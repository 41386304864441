@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;700&family=Luckiest+Guy&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* font-family: 'Advent Pro', sans-serif; weight 400, 700*/
/* font-family: 'Luckiest Guy', cursive; */

/* #instafeed-container img{
  width: 20%;
  border: 5px 5px 25px 5px;
  border-style: solid;
  border-color: rgb(240, 237, 233);
  
}

#instafeed-container{
  margin-left: 2%;
  margin-right: 2%;
} */

