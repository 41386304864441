
html{
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;

}

#root{
  scroll-behavior: smooth;
  text-align: center;
  color: white;
  background-color: #282c34;
  background-image: linear-gradient(62deg, #051e30 19%, black 74%, #011a2d 100%);

}

.App {
  text-align: center;
  padding-top: 7rem;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


#navigation {
  background: linear-gradient(#000000, #1a2132cf);
  z-index: 1;
  width: 100vw;
  font-family: 'Advent Pro', sans-serif;
  font-size: 23px;
}


#navigationSub{
  text-align: end;

}

.landing{
  height: 55vh;
  width: 90vw;
  display: inline-flex;
  margin-bottom: 20px;
}

.lHeader {
  font-family: 'Luckiest Guy', cursive;
  margin-left: auto;
  margin-right: auto;
}


.headerDiv{
  display: flex;
    justify-content: center;
}



#mabuhay{
  font-size: 55px;
  letter-spacing: 1px;
  text-align: center;
  padding: 3% 3%;
}

#h1Landing{
  color: #caf000;
}

#name2{
  font-size: 115px;
  letter-spacing: 2px;
  text-align: center;
}


#tab1, #tab2, #tab3, #tab4, #tab5{
  text-decoration: none;
  color: #bfff00;
  display: flex;
 padding-right: 18px;
}

#tab1:hover, #tab2:hover, #tab3:hover, #tab4:hover, #tab5:hover,#tab1:focus, #tab2:focus, #tab3:focus, #tab4:focus, #tab5:focus{
  transform: scale(1.08);
  font-weight: bolder;
  transition: all .2s;

}

.summary{
  padding-left: .5em;
  padding-right: .5em;
  font-family: 'Advent Pro', sans-serif;
  font-weight: 400;
  text-align: center;
}

#contactButton{
  margin-top: 20px;
  border-color: #caf000;
}

#contactButton2{
  margin-top: 20px;
  margin-left: 8px;
  border-color: #caf000;
}

#contact{
  font-family: 'Luckiest Guy', cursive;
  height: 20vh;
}

#contact svg{
  margin: 8px;
  color: #caf000;
}

.icon {

  transform: scale(1);
  transition: all .25s;

}


.icon:hover, .icon:focus {
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
}

#footer{
  background-color: black;
  height: 35px;
  width: 100vw;
  /* position: absolute; */
  cursor:nw-resize;
  /* display: contents; */
}

#footText{
  font-family: 'Advent Pro', sans-serif;
  font-variant: petite-caps;
  color: #caf000;
  padding: 1px;
  border-color:black;
  border-bottom: dotted;
  border-bottom-width: 1px;
  border-bottom-color:#494d55;
}



.logo{
  position: fixed;
  left: 19px;
  width: 45px;
  bottom: 14px;
}

.tooltip {
  display:inline-block;
  position:relative;
  border-bottom:1px dotted #666;
  text-align:left;
}



/* MOBILE PORTRAIT */
@media (min-width: 320px) and (max-width: 480px){

  .landing {
      height:max-content;
  }

  .App{
    padding-top: 5rem;
  }

  #contact{
    padding-top: 3em;
    height: 35vh;
  }

  .headerDiv{
    height: 8.5em;
  }

  .summary{
    font-size:1.8em;
  }

  #mabuhay{
    font-size: 2rem;
  }

  #name2{
    font-size: 5rem;
  }

  #tab1, #tab2, #tab3, #tab4, #tab5{
    padding-left:15px;
  }
}
/* MOBILE LANDSCAPE */
@media (min-width: 481px) and (max-width: 767px){
  .App{
    padding-top: 5rem;
  }

  .landing{
    height: min-content;
  }

  #contact {
    height: min-content;
    margin-bottom: 6em;
  }

  #tab1, #tab2, #tab3, #tab4, #tab5{
    padding-left:15px;
  }

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){

  #contact {
    height: min-content;
    margin-bottom: 6em;
  }

  #tab1, #tab2, #tab3, #tab4, #tab5{
    padding-left:15px;
  }

}